<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <a-card>
        <a-row style="margin-bottom: 20px;">
          <a-col :span="24" class="d-flex justify-content-end">
            <a-range-picker
                showTime
                :ranges="{ 现在: [moment(), moment()] }"
                :placeholder="[l('audit.planstart'), l('audit.planend')]"
                format="YYYY-MM-DD HH:mm:ss"
                style="text-align: center;width: 350px;margin-right: 10px"
                @change="timechange"
            />
            <a-tree-select
                style="width: 200px;margin-right: 10px;"
                :tree-data="workProcessData"
                :replace-fields="treeReplace"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :placeholder="l('Please_select_a_point')"
                allow-clear
                tree-default-expand-all
                @change="workProcessClassChange"
            >
            </a-tree-select>
            <a-select showSearch :filterOption="filterOption"
                      style="width: 200px;text-align: left;margin-right: 10px;"
                      dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" :placeholder="l('Please_select_a_punch_card_person')"
                      allow-clear
                      @change="LPAUserChange"
            >
              <a-select-option v-for="item in AllLPAUser" :key="item.id"
                               :title="item.realName">
                {{ item.realName }}
              </a-select-option>
            </a-select>
            <a-input-search :placeholder="l('Please_enter_a_point_search')" v-model.trim="filterText" enterButton
                            style="width: 200px;" @search="filteronchange"></a-input-search>
          </a-col>
        </a-row>
        <a-spin :spinning="isLoading">
          <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                   :rowKey="(record)=>record.id" :scroll="{x:800,y:610}" :pagination="false" size="small">
          </a-table>
        </a-spin>
        <a-pagination
            class="pagination"
            :total="totalItems"
            :show-total="total => `共有 ${totalItems} 条`"
            showSizeChanger
            showQuickJumper
			:defaultPageSize="request.maxResultCount"
			:pageSizeOptions="pageSizeOptions"
            @change="onChangePage"
            @showSizeChange="showSizeChange"/>
      </a-card>
    </a-config-provider>
  </div>
</template>

<script>
import {
  AppComponentBase
} from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
  PointCheckServiceProxy,AuditTaskServiceProxy,AuditPointServiceProxy
} from '../../../shared/service-proxies';
import CreatErrorCode from "../organization-structure/new-template/new-template";
import moment from "moment";

let _this;
export default {
  name: "punch-card-records",
  mixins: [AppComponentBase],
  components: {
    CreatErrorCode,
  },
  created() {
    _this = this;
    this.PointCheckServiceProxy = new PointCheckServiceProxy(this.$apiUrl, this.$api);
    this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
    this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this.getData();
    this.getAllLPAUser();
    this.getWorkProcessData();
  },
  data() {
    return {
      zh_CN,
      isEdit: false,
      isLook: false,

      filterText: undefined,
      totalItems: 0, //总数
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "50", "100", "500"],
      request: {
        maxResultCount: 20,
        skipCount: 0
      },

      tableData: [],
      columns: [{
        title: this.l('Punch_Card_Time'),
        width: 200,
        dataIndex: 'creationTime',
        align: 'center',
      },
        {
          title: this.l('Punch_Card_Person'),
          width: 150,
          dataIndex: 'creatorUserName',
          align: 'center',
        },
        {
          title: this.l('Points'),
          width: 150,
          dataIndex: 'auditPointName',
          align: 'center',
          ellipsis: true
        },
        {},
      ],
      selectedRowKey: [], // 选择的数据Id
      selectedRow: [], //选择的整行数据
      isLoading: false, //加载中
      children: [],
      AllLPAUser: undefined,
      workProcessData: [],
      treeReplace: {
        title: 'name',
        key: 'id',
        value: 'id'
      },
    }
  },
  methods: {
    moment,
    //获取数据
    getData() {
      this.selectedRowKey = [];
      this.isLoading = true;
      this.PointCheckServiceProxy.getPaged(
          this.workProcessClass,
          this.UserId,
          this.startTime,
          this.endTime,
          this.filterText ? this.filterText : undefined,
          undefined,
          this.request.maxResultCount,
          this.request.skipCount
      ).finally(() => {
        this.isLoading = false;
      }).then(res => {
        console.log(res);
        this.tableData = res.items;
        this.tableData.map(item=>{
          item.creationTime=item.creationTime?moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss"):"";
        });

        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      })
    },
    timechange(date, time) {
      console.log(time);
      if (date.length > 0) {
        this.startTime = moment(time[0]);
        this.endTime = moment(time[1]);
        //await this.getCycle();
      } else {
        this.startTime = undefined;
        this.endTime = undefined;
      }
      this.getData();
    },
    //获取审核点
    getWorkProcessData() {
      this.isLoading = true;
      this.AuditPointServiceProxy.getAllData(
      ).finally(() => {
        this.isLoading = false;
      }).then(res => {
        console.log(res);
        this.workProcessData = JSON.parse(JSON.stringify(res));
      })
    },
    getAllLPAUser(){
      this.isLoading = true;
      this.AuditTaskServiceProxy.getAllLPAUsers(
      )
          .finally(() => {
            this.isLoading = false;
          })
          .then((res) => {
            console.log(res);
            this.AllLPAUser = res;
          })
    },
    workProcessClassChange(key) {
      console.log(key);
      this.workProcessClass = key;
      this.getData();
    },
    LPAUserChange(value){
      console.log(value);
      this.UserId = value;
      this.getData();
    },
    //行选择
    customRow: (record) => {
      return {
        on: {
          click: () => {
            _this.onSelect(record)
          }
        }
      }

    },
    //多选
    SelectAll(record, rows, crows) {
      if (record) {
        //更改表格数据勾选状态
        this.tableData.map(item => {
          item.checked = true;
        });
        rows.forEach((item, index) => {
          //判断是否存在
          let u = this.selectedRowKey.find(t => t == item.id);
          if (!u) {
            this.selectedRowKey = [...this.selectedRowKey, item.id];
          }
        });
      } else {
        //更改表格数据勾选状态
        this.tableData.forEach((item, index) => {
          item.checked = false;
          this.selectedRowKey = [];
        });
      }
    },
    //分页
    onChangePage(page, pageSize) {
      this.selectedRowKey = [];
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    //正常分页展示形式，显示（共有 {0} 条）
    // showTotalFun() {
    // 	console.log(this.totalItems);
    //     return this.l(
    //         "GridFooterDisplayText",
    //         this.totalItems
    //     );
    // },
    showSizeChange(current, size) {
      this.selectedRowKey = [];
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
      this.getData();
    },
    // 重写复选框点击事件(不可删除)
    onSelect(record) {
      //debugger;
      console.log(record);
      record.checked = !record.checked;
      if (record.checked) {
        this.selectedRowKey.push(record.id);
        this.children.push(record.children);
      } else {
        this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
        this.children = _.difference(_this.children, [record.children]);
      }
    },
    //搜索框点击事件
    filteronchange() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>

<style scoped>
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>